.team-container {
	background: radial-gradient(#ffffff, #d5d5d5);
	background-size: cover;
	background-position: top center;
	position: relative;
	min-height: 100vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.team-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 5rem;
}

.talati {
	height: 18rem;
}

.team-body h1 {
	display: block;
	width: fit-content;
	font-size: 4rem;
	color: #000;
	letter-spacing: 0.1em;
	font-family: "Montserrat-Light", sans-serif !important;
	font-weight: 100;
	text-transform: uppercase;
	padding-bottom: 30px;
	/* padding-top: 60px; */
}

.team-body p {
	display: inline-block;
	color: #000;
	letter-spacing: 0.1em;
	line-height: 2rem;
	font-family: "Roboto-Condensed", "Roboto-Condensed-Local", "Roboto", Helvetica,
		sans-serif !important;
	font-weight: 300;
	font-size: 1.5rem;
	text-align: center;
	padding-top: 1rem;
	width: 30rem;
	/* max-width: 360px; */
}

.row {
	justify-content: center;
	align-items: center;
}

.row ul {
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	justify-content: center;
	align-items: flex-start;
}

.row li {
	padding: 20px 40px;
	text-align: center;
}

.row h2 {
	display: block;
	font-size: 2.5rem;
	letter-spacing: 0.1em;
	font-family: "Montserrat-Light", Helvetica, sans-serif;
	font-weight: 100;
	padding-top: 2rem;
	text-align: center;
	color: #000;
	text-decoration: none;
}

.row h2:hover {
	text-decoration: underline 1px;
	text-underline-offset: 2px;
}

.lastfooter {
	padding-top: 10rem;
	display: flex;
	font-weight: 500;
	padding-bottom: 30px;
	margin: 0 auto;
	text-align: center;
	color: #000;
}

.lastfooter a {
	margin: 0 auto;
	text-align: left;
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	line-height: 2rem;
	font-weight: 400;
	font-size: 1.5rem;
	padding-top: 1rem;
	max-width: 25vw;
}

.hyperlink {
	color: #000;
	text-decoration: none;
}

.hyperlink:hover {
	text-decoration: underline 1px;
	text-underline-offset: 1px;
}

.down-arrow {
	justify-content: center;
	position: absolute;
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	bottom: 30px;
	height: 50px;
	left: 50%;
	margin: 0 0 0 -100px;
	width: 200px;
}

.down-arrow svg {
	width: 5rem;
	display: flex;
}

@media screen and (max-width: 1240px) {
	.talati {
		height: 12rem;
	}

	.team-body h2 {
		font-size: 2.5rem;
	}

	.team-body p {
		font-size: 1.5rem;
	}
}

@media screen and (max-width: 500px) {
	.team-body {
		padding-bottom: 75px;
	}

	.team-body h1 {
		padding-bottom: 10px;
	}

	.team-body li {
		padding: 25px;
	}
	.team-body h2 {
		font-size: 2.2rem;
		padding-top: 2px;
	}

	.team-body p {
		font-size: 1.2rem;
		padding-top: 2px;
	}
}
