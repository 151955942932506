.portfolio-container {
	background: radial-gradient(rgb(31, 31, 31), rgb(60, 60, 60));
	background-size: cover;
	background-position: top center;
	position: relative;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.portfolio-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
}

.portfolio-body h1 {
	display: block;
	width: fit-content;
	font-size: 4rem;
	color: white;
	letter-spacing: 0.1em;
	font-family: "Montserrat-Light", Helvetica, sans-serif !important;
	font-weight: 100;
	text-transform: uppercase;
	padding-bottom: 30px;
	/* padding-top: 60px; */
}

.carousel-root {
	width: 1200px;
	max-width: 90vw;
}

.carousel-div-desktop {
	display: flex;
}

.carousel-div-mobile {
	display: none;
}

.carousel-ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	/* width: 400px; */
}

.control-dots {
	display: none;
}

.carousel-ul li {
	width: 25rem !important;
	height: 7.5rem !important;
}

.carousel-ul a {
	display: flex;
	justify-content: center;
	width: 100% !important;
	height: 100% !important;
}

.logo-img {
	display: block;
	height: 4rem !important;
	width: auto !important;
}

.logo-img-kyte {
	display: block;
	height: 4rem !important;
	width: auto !important;
	transform: translateY(3px);
}

.logo-img-stonks {
	display: block;
	height: 4rem !important;
	width: auto !important;
	transform: translateY(3px);
	transform: translateX(-3px);
}

.logo-img-spc {
	display: block;
	height: 3.5rem !important;
	width: auto !important;
	transform: translateY(3px);
}

.airplane:hover {
	content: url("./images/color/airplane.png") !important;
}

.control-arrow:hover {
	background: none !important;
}

.down-arrow {
	justify-content: center;
	position: absolute;
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	bottom: 30px;
	height: 50px;
	left: 50%;
	margin: 0 0 0 -100px;
	width: 200px;
}

.down-arrow svg {
	width: 5rem;
	display: flex;
	fill: white;
}

.logo-link-1 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-1 img:last-child {
	display: none;
}
.logo-link-1:hover img:first-child {
	display: none;
}
.logo-link-1:hover img:last-child {
	display: inline-block;
}

.logo-link-2 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-2 img:last-child {
	display: none;
}
.logo-link-2:hover img:first-child {
	display: none;
}
.logo-link-2:hover img:last-child {
	display: inline-block;
}

.logo-link-3 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-3 img:last-child {
	display: none;
}
.logo-link-3:hover img:first-child {
	display: none;
}
.logo-link-3:hover img:last-child {
	display: inline-block;
}

.logo-link-4 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-4 img:last-child {
	display: none;
}
.logo-link-4:hover img:first-child {
	display: none;
}
.logo-link-4:hover img:last-child {
	display: inline-block;
}

.logo-link-5 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-5 img:last-child {
	display: none;
}
.logo-link-5:hover img:first-child {
	display: none;
}
.logo-link-5:hover img:last-child {
	display: inline-block;
}

.logo-link-6 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-6 img:last-child {
	display: none;
}
.logo-link-6:hover img:first-child {
	display: none;
}
.logo-link-6:hover img:last-child {
	display: inline-block;
}

.logo-link-7 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-7 img:last-child {
	display: none;
}
.logo-link-7:hover img:first-child {
	display: none;
}
.logo-link-7:hover img:last-child {
	display: inline-block;
}

.logo-link-8 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-8 img:last-child {
	display: none;
}
.logo-link-8:hover img:first-child {
	display: none;
}
.logo-link-8:hover img:last-child {
	display: inline-block;
}

.logo-link-9 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-9 img:last-child {
	display: none;
}
.logo-link-9:hover img:first-child {
	display: none;
}
.logo-link-9:hover img:last-child {
	display: inline-block;
}

.logo-link-10 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-10 img:last-child {
	display: none;
}
.logo-link-10:hover img:first-child {
	display: none;
}
.logo-link-10:hover img:last-child {
	display: inline-block;
}

.logo-link-11 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-11 img:last-child {
	display: none;
}
.logo-link-11:hover img:first-child {
	display: none;
}
.logo-link-11:hover img:last-child {
	display: inline-block;
}

.logo-link-12 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-12 img:last-child {
	display: none;
}
.logo-link-12:hover img:first-child {
	display: none;
}
.logo-link-12:hover img:last-child {
	display: inline-block;
}

.logo-link-13 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-13 img:last-child {
	display: none;
}
.logo-link-13:hover img:first-child {
	display: none;
}
.logo-link-13:hover img:last-child {
	display: inline-block;
}

.logo-link-14 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-14 img:last-child {
	display: none;
}
.logo-link-14:hover img:first-child {
	display: none;
}
.logo-link-14:hover img:last-child {
	display: inline-block;
}

.logo-link-15 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-15 img:last-child {
	display: none;
}
.logo-link-15:hover img:first-child {
	display: none;
}
.logo-link-15:hover img:last-child {
	display: inline-block;
}

.logo-link-16 {
	display: block;
	margin: 2rem;
	justify-content: center;
	cursor: pointer;
	width: 40rem;
}

.logo-link-16 img:last-child {
	display: none;
}
.logo-link-16:hover img:first-child {
	display: none;
}
.logo-link-16:hover img:last-child {
	display: inline-block;
}

@media (max-width: 1000px) {
	.portfoliowrap {
		width: 75vw;
		margin-bottom: 10rem;
	}

	.portfolio-body h1 {
		padding-top: 60px;
	}
}

@media (max-width: 500px) {
	.portfolio-body {
		transform: translateY(-5rem);
	}

	.carousel-div-desktop {
		display: none;
	}

	.carousel-div-mobile {
		display: flex;
	}

	.carousel-root {
		width: 75vw;
	}
	.logo-img {
		/* height: auto !important; */
		max-width: 60vw !important;
	}

	.logo-img-spc {
		height: 31.97px !important;
		width: 200px !important;
	}

	.down-arrow-spc {
		padding-bottom: 12rem !important;
	}
}
