.title-container {
	background: radial-gradient(rgb(31, 31, 31), rgb(60, 60, 60));
	background-size: cover;
	background-position: top center;
	position: relative;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/* --safe-area-inset-top: env(safe-area-inset-top);
	--safe-area-inset-bottom: env(safe-area-inset-bottom);
	height: calc(
		100% + (var(--safe-area-inset-top) + var(--safe-area-inset-bottom))
	); */
}

.header {
	display: flex;
	flex-direction: column;
	margin: 0;
	z-index: 994;
}

.logo-container {
	display: flex;
	margin: 0;
	justify-content: center;
	/* transform: translateX(0.5rem); */
	/* flex-direction: row; */
}

.logo-container svg {
	fill: black;
	z-index: 995;
	margin: 0;
	padding: 0;
}

.input-logo {
	width: 460px;
}

.input-arrow {
	display: block;
	height: 2.5rem;
	align-items: center;
	justify-content: center;
	transform: translateY(1.5rem);
	padding-right: 2rem;
}

.header-menu {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	padding-top: 20px;
	z-index: 996;
}

.menu-item {
	display: block;
	color: white;
	font-size: 26px;
	z-index: 999;
	text-transform: uppercase;
	font-family: Helvetica, sans-serif !important;
	letter-spacing: 0.1em;

	font-weight: 100;
	list-style: none;
}

.slash {
	display: block;
	color: white;
	font-family: Helvetica, sans-serif !important;
	font-weight: 100;
	text-transform: uppercase;
	letter-spacing: 0.1em;
	font-size: 2.2rem;
	padding-left: 26px;
	padding-right: 26px;
	transform: translateY(0px);
	z-index: 993;
}

.down-arrow {
	justify-content: center;
	position: absolute;
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	bottom: 30px;
	height: 50px;
	left: 50%;
	margin: 0 0 0 -100px;
	width: 200px;
}

.down-arrow svg {
	width: 5rem;
	display: flex;
}

@media (max-width: 500px) {
	.header {
		transform: translateY(-5rem);
	}
	.input-logo {
		width: 289px;
	}

	.menu-item {
		font-size: 18px;
	}

	.slash {
		font-size: 18px;
		padding-left: 10px;
		padding-right: 10px;
	}

	.down-arrow {
		padding-bottom: 7rem;
	}

	.down-arrow-spc {
		padding-bottom: 12rem !important;
	}
}
