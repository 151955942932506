.about-container {
	background: radial-gradient(#ffffff, #d5d5d5);
	background-size: cover;
	position: relative;
	height: 100vh;
	width: 100vw;
	display: flex;
	align-items: center;
	justify-content: center;
}

.about-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.about-body h1 {
	display: block;
	width: fit-content;
	font-size: 4rem;
	color: black;
	letter-spacing: 0.1em;
	font-family: "Montserrat-Light", Helvetica, sans-serif !important;
	font-weight: 100;
	text-transform: uppercase;
	padding-bottom: 10px;
}

.about-body p {
	display: inline-block;
	padding-top: 10px;
	color: black;
	letter-spacing: 0.1em;
	line-height: 4rem;
	font-family: "Roboto-Condensed", "Roboto-Condensed-Local", "Roboto", Helvetica,
		sans-serif !important;
	font-weight: 300;
	font-size: 1.8rem;
	text-align: center;
	padding-bottom: 10px;
	margin: 0;
}

.p-1 {
	/* width: 890px; */
	width: 50vw;
}

.p-2 {
	/* width: 750px; */
	width: 40vw;
}

.down-arrow {
	justify-content: center;
	position: absolute;
	align-items: center;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	bottom: 30px;
	height: 50px;
	left: 50%;
	margin: 0 0 0 -100px;
	width: 200px;
}

.down-arrow svg {
	width: 5rem;
	display: flex;
	fill: white;
}

@media screen and (max-width: 500px) {
	.about-body {
		transform: translateY(-5rem);
	}
	.about-body h1 {
		font-size: 4rem;
	}

	.about-body p {
		font-size: 1.4rem;
	}

	.p-1 {
		width: 80vw;
	}

	.p-2 {
		width: 70vw;
	}

	.down-arrow-spc {
		padding-bottom: 12rem !important;
	}
}
