@font-face {
	font-family: "Roboto-Condensed-Local";
	src: local("Roboto-Condensed-Local"),
		url("/fonts/Roboto_Condensed/RobotoCondensed-Light.ttf");
}
@font-face {
	font-family: "Montserrat-Light";
	src: local("Montserrat-Light"),
		url("/fonts/Montserrat/static/Montserrat-ExtraLight.ttf");
}

@font-face {
	font-family: "Roboto-Condensed";
	src: url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300&display=swap");
}

@font-face {
	font-family: "Montserrat";
	src: url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100&display=swap");
}

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	display: none;
}

html {
	font-size: 10px;
	scroll-behavior: smooth;
}

h1 h2 {
	font-family: "Montserrat", Helvetica, sans-serif !important;
}

p {
	font-family: "Roboto-Condensed", Helvetica, sans-serif !important;
}

a {
	text-decoration: none;
}
