.contact-container {
	background: radial-gradient(rgb(31, 31, 31), rgb(60, 60, 60));
	background-size: cover;
	background-position: top center;
	position: relative;
	min-height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.contact-body {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100vw;
}

.contact-body p {
	display: block;
	color: #fff;
	letter-spacing: 0.1rem;
	line-height: 2rem;
	font-family: "Roboto-Condensed", "Roboto-Condensed-Local", "Roboto", Helvetica,
		sans-serif !important;
	font-weight: 300;
	font-size: 2rem;
	text-align: center;
	padding: 10px;
}

.email-link {
	text-decoration: none;
	color: #fff;
	transition: all 1s;
}

.email-link:hover {
	text-decoration: underline 1px;
	text-underline-offset: 1px;
}

@media (max-width: 500px) {
	.contact-body {
		transform: translateY(-5rem);
	}
}
